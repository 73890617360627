import React, { useContext, useEffect, useState } from 'react';
import {
    ApplicationComponents,
    ApplicationLink,
    Athlete,
    AthleteResult,
    Components,
    formatAmount,
    formatNullableScore,
    formatScore,
    OwnedAthlete,
    Race,
    Selection,
    Settings,
    SettingsContext
} from "@fantasy/components";
import { AthleteApp } from '@fantasy/components/src/components/AthleteApp';
import { VismaAthlete, VismaSelection } from "./models";
import { ColumnDef, Getter } from "@tanstack/react-table";
import { AthleteName } from "./ReactComponents.tsx";

function displayAthlete(athlete: VismaAthlete) {
    return <ApplicationLink href={`/athletes/${athlete.athlete_id}`}>
        <AthleteName athlete={athlete}/>
    </ApplicationLink>;
}

const vismaColumns: ColumnDef<VismaAthlete & { prev_race_pts: number }>[] = [
    {
        header: 'Name',
        id: 'name',
        accessorFn: (athlete: VismaAthlete) => athlete,
        cell: ({ getValue }: { getValue: Getter<VismaAthlete> }) => <>
            <div>
                <img src={getValue().pro_team.logo_url} />
            </div>
            <div>
                <p>{ displayAthlete(getValue()) }</p>
                <p className={"athleteTeamName"}>{ getValue().pro_team.name }</p>
            </div>
        </>,
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
        sortDescFirst: false,
    },
    {
        header: 'Price',
        accessorFn: (obj: VismaAthlete) => formatAmount(obj.price),
        sortingFn: (a, b) => a.original.price - b.original.price,
        sortDescFirst: true,
    },
    {
        header: 'Score',
        accessorFn: (obj: VismaAthlete) => formatScore(obj.score || 0),
        sortingFn: (a, b) => (a.original.score || 0) - (b.original.score || 0),
        sortDescFirst: true,
    },
    {
        header: 'Prev race pts.',
        id: 'prev_race_pts',
        accessorFn: (obj) => formatNullableScore(obj.prev_race_pts, '-'),
        sortingFn: (a, b) => (a.original.prev_race_pts | 0) - (b.original.prev_race_pts | 0),
    },
    {
        header: '',
        accessorKey: 'trade',
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<React.JSX.Element>} ) => getValue()
    }
];

const VismaAthletesApp = () => {
    const components = useContext<Components>(ApplicationComponents);
    const [lastResults, setLastResults] = useState<Map<number, { prev_race_pts: number }>>(new Map<number, { prev_race_pts: number }>());
    const settings = useContext<Settings | undefined>(SettingsContext);

    useEffect(() => {
        if (!settings?.current_season) {
            return;
        }
        async function fetchLastRaceResult(gender: 'm' | 'f' | 'mixed'): Promise<AthleteResult[]> {
            return components.httpService.lastScoredRace(gender)
                .then(async (race: Race) => {
                    const weekend = await components.httpService.weekend(race.weekend_id);
                    if (weekend.season === settings?.current_season) {
                        return components.httpService.raceResults(race.race_id);
                    } else {
                        return [];
                    }

                });
        }

        Promise.all([fetchLastRaceResult('m'), fetchLastRaceResult('f')])
        .then((results) => {
            setLastResults(
                results.flat().reduce((map, result) => map.set(result.athlete_id, {prev_race_pts: result.score}), new Map())
            );
        });
    }, [components.httpService, settings?.current_season]);

    return <AthleteApp columns={vismaColumns} extraData={lastResults} allowBuyingFunction={vismaAllowBuying} />
}

const vismaAllowBuying = (athlete: Athlete, balance: number, ownedAthletes: OwnedAthlete[], activeSelection: Selection) => {
    if (!activeSelection) {
        return false;
    }
    if (athlete.price > balance) {
        return false;
    }
    let maxPicksForCategory = activeSelection.max_picks_per_gender
    let vismaAthlete = athlete as VismaAthlete;
    if (vismaAthlete.is_cub) {
        return ownedAthletes.length < activeSelection.maxAthletePicks()
    }
    const vismaSelection = activeSelection as VismaSelection;
    const ownedNormalAthletes = ownedAthletes.filter(ownedAthlete => !(ownedAthlete.athlete as VismaAthlete).is_cub)
    const ownedAthletesInCategory = ownedNormalAthletes.filter(ownedAthlete => ownedAthlete.athlete.gender == athlete.gender)
    return ownedAthletesInCategory.length < maxPicksForCategory && ownedNormalAthletes.length < vismaSelection.max_picks && ownedAthletes.length < activeSelection.maxAthletePicks();
}

export default VismaAthletesApp;
