import { Athlete, OwnedAthlete, Selection, Settings, User, Weekend } from '@fantasy/components';
import { SelectionResponse, WeekendResponse } from "@fantasy/components/src/components/responses.ts";


export interface ProTeam {
    abbreviation: string;
    name: string;
    logo_url: string;
}

export interface VismaAthlete extends Athlete {
    pro_team: ProTeam;
    youth: boolean;
    is_qci_bonus_eligible: boolean;
    is_cub: boolean;
}

export interface OwnedVismaAthlete extends OwnedAthlete {
    athlete: VismaAthlete;
}

export interface VismaUser extends User {
    verified_user: boolean;
}

export interface VismaWeekendResponse extends WeekendResponse {
    livestream_link: string;
    event_link: string;
}

export interface VismaSelectionResponse extends SelectionResponse {
    max_picks: number;
    cub_picks: number;
}

export class VismaSelection extends Selection {
    max_picks: number;
    cub_picks: number;

    constructor(selection: VismaSelectionResponse) {
        super(selection);
        this.max_picks = selection.max_picks;
        this.cub_picks = selection.cub_picks;
    }

    maxAthletePicks(): number {
        return this.max_picks + this.cub_picks;
    }
}

export class VismaWeekend extends Weekend {
    livestream_link: string;
    event_link: string;
    constructor(weekend: VismaWeekendResponse) {
        super(weekend, (sel) => new VismaSelection(sel as VismaSelectionResponse));
        this.livestream_link = weekend.livestream_link;
        this.event_link = weekend.event_link;
    }
}

export interface CupScores {
    athlete_id: number,
    sprint: number,
    climb: number,
    champion: number;
    youth: number;
    qci: number;
    rank: number;
    total: number;
    athlete: VismaAthlete;
}

export interface VismaSettings extends Settings {
    oauth_register_link: string;
}
