import React, { useContext, useState } from 'react';
import {
    CheckboxField,
    CountryDropdown,
    displayErrorMessage,
    EmailField,
    EmailValidation,
    ErrorResponse,
    FieldContainer,
    HTTP_CLIENT,
    LoadingIndicator,
    RankedUser,
    RecaptchaField,
    Settings,
    SettingsContext,
    TextField,
    useDoLogin,
    useRedirectToHomePageIfLoggedIn
} from '@fantasy/components';
import { useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { VismaSettings } from "./models";

interface RegisterFields {
    first_name: string;
    last_name: string;
    user_name: string;
    email: string;
    password: string;
    password_confirm: string;
    country: string;
    team_name: string;
    agreement: boolean;
    register_source: string;
    "g-recaptcha-response": string;
    proxcskiing_newsletter_consent: boolean;
}

export const VismaRegisterUserPage = () => {
    const login = useDoLogin();
    useRedirectToHomePageIfLoggedIn();
    const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map<string, string>());
    const settings = useContext<Settings | undefined>(SettingsContext) as VismaSettings;
    const [searchParams] = useSearchParams();
    const isInOauthFlow = !!searchParams.get("register_source");
    const defaultValues: Partial<RegisterFields> = Object.fromEntries(searchParams.entries());
    defaultValues.proxcskiing_newsletter_consent = true;
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch
    } = useForm<RegisterFields>({defaultValues: defaultValues});
    const onSubmit: SubmitHandler<RegisterFields> = data => {
        HTTP_CLIENT.post('/api/register', data, undefined, {useGlobalErrorHandling: false})
            .then(() => {
                HTTP_CLIENT.get<RankedUser>('/api/me/rank')
                    .then((response) => {
                        login(response.data);
                    })
                    .catch(() => {
                        window.location.href = '/';
                    });
            })
            .catch((error: ErrorResponse<any>) => {
                if (error.response?.data.response) {
                    if (error.response.data.response.field_errors) {
                        setFormErrors(new Map(Object.entries(error.response.data.response.field_errors)));
                    } else {
                        displayErrorMessage(error.response.data.response.errors);
                    }
                } else {
                    displayErrorMessage("Something went wrong, please refresh the webpage");
                }
            });
    }

    const copyMap = new Map<string, string>(formErrors);
    if (errors) {
        for (const field in errors) {
            const value = (errors as any)[field];
            if (value.message) {
                copyMap.set(field, value.message);
            }
        }
    }

    const firstNameValidation = {
        required: {
            value: true,
            message: "You need to enter your first name"
        },
        maxLength: {
            value: 120,
            message: "Your first name can be at most 120 characters long"
        }
    }

    const lastNameValidation = {
        required: {
            value: true,
            message: "You need to enter your last name"
        },
        maxLength: {
            value: 120,
            message: "Your last name can be at most 120 characters long"
        }
    }

    const userNameValidation = {
        required: true,
        message: "You need to pick a user name",
        maxLength: {
            value: 120,
            message: "Your user anme can be at most 120 characters long"
        }
    }

    const confirmPasswordValidation = {
        validate: (v: string) => v === watch("password") || "Passwords does not match",
        required: {
            value: !isInOauthFlow,
            message: "You need to confirm the password"
        }
    };

    const passwordValidation = {
        required: {
            value: !isInOauthFlow,
            message: "You need a password"
        },
        minLength: {
            value: 8,
            message: "Passwords needs to be at least 8 characters"
        }
    };

    const agreementValidation = {
        required: {
            value: true,
            message: "You need to agree to the terms and conditions"
        }
    }


    if (!settings) {
        return <LoadingIndicator></LoadingIndicator>;
    }

    if (!isInOauthFlow && settings?.login_oauth_provider) {
        window.location.href = `/oauth/${settings.login_oauth_provider}/redirect`;
        return <LoadingIndicator>
            <span>We are redirecting you to the login provider</span>
        </LoadingIndicator>;
    }

    return <>
        <h1>Register</h1>
        {isInOauthFlow && <p>
            It looks like you don't have a Ski Classics Fantasy user account. Please register before logging in.
        </p>}
        <form className="register_form" onSubmit={ handleSubmit(onSubmit) }>
            <FieldContainer error={ copyMap.get("first_name") } label={ "First Name" }>
                <TextField registerFields={ register("first_name", firstNameValidation) }
                           disabled={ searchParams.has("first_name") }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("last_name") } label={ "Last Name" }>
                <TextField registerFields={ register("last_name", lastNameValidation) }
                           disabled={ searchParams.has("last_name") }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("user_name") } label={ "User Name" }>
                <TextField registerFields={ register("user_name", userNameValidation) }
                           disabled={ searchParams.has("user_name") }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("email") } label={ "Email" }>
                <EmailField register={ register("email", EmailValidation) } disabled={ searchParams.has("email") }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("password") } label={ isInOauthFlow ? undefined : "Password" }>
                <TextField registerFields={ register("password", passwordValidation) } type={ "password" }
                           disabled={ searchParams.has("password") } hidden={ isInOauthFlow }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("password_confirm") }
                            label={ isInOauthFlow ? undefined : "Confirm Password" }>
                <TextField registerFields={ register("password_confirm", confirmPasswordValidation) }
                           hidden={ isInOauthFlow } label={ "Retype Password" } type={ "password" }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("country") } label={ "Country" }>
                <CountryDropdown register={ register } fieldName={ "country" }/>
            </FieldContainer>
            <FieldContainer error={ copyMap.get("team_name") } label={ "Team Name" }>
                <TextField registerFields={ register("team_name") } disabled={ searchParams.has("team_name") }/>
            </FieldContainer>
            <CheckboxField registerFields={ register("agreement", agreementValidation) }
                           error={ copyMap.get("agreement") }
                           label={ <>I hereby agree that Ski Classics may store results, cookies, personal data and send
                               me news in accordance with <a href='https://skiclassics.com/privacy-policy/' target='_blank'>
                                   Ski Classics Privacy Policy
                               </a>.
                               I have used my real name when registering, I am 18 years or older and this is the
                               only
                               account that I will create.</> }/>
            <CheckboxField registerFields={ register("proxcskiing_newsletter_consent") }
                           error={ copyMap.get("proxcskiing_newsletter_consent") }
                           label={ "I want to get news and offers from SC the Ski Community" }/>
            <FieldContainer>
                <TextField registerFields={ register("register_source") } type={ "text" } hidden={ true }/>
            </FieldContainer>
            <RecaptchaField registerFields={ register("g-recaptcha-response") }/>
            <input type="submit" value="Sign Up!" className="button" disabled={ !settings }/>
        </form>
    </>
}
