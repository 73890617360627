import { ApplicationComponents, ProviderProps } from "@fantasy/components";
import React from "react";
import { AthleteDisplay, AthleteIndicators, UserDisplay, VismaFlag } from "./ReactComponents";
import { VismaAthlete } from "./models";
import { HTTP_SERVICE } from "./Util";

export const ApplicationComponentProvider = (props: ProviderProps) => <ApplicationComponents.Provider value={ {
    flag: (country) => <VismaFlag country={ country }/>,
    userDisplay: (country) => <UserDisplay user={ country }/>,
    athleteDisplay: (athlete) => <AthleteDisplay athlete={ athlete as VismaAthlete }/>,
    athleteIndicators: (athlete) => <AthleteIndicators athlete={ athlete as VismaAthlete }/>,
    httpService: HTTP_SERVICE,
} }>
    { props.children }
</ApplicationComponents.Provider>;
