import { Params } from "react-router-dom";
import { Athlete, AthletesAppLoadedData, User } from "@fantasy/components";
import { requestData } from "@fantasy/components/src/components/Util.ts";
import { weekendLoader } from "@fantasy/components/src/components/loaders.ts";
import { WeekendResponse } from "@fantasy/components/src/components/responses.ts";
import { VismaWeekend, VismaWeekendResponse } from "./models.tsx";


export const vismaWeekendLoader = async ({ params }: {params: Params<string>}): Promise<any> => {

    return Promise.all([
        weekendLoader({params}),
        requestData<WeekendResponse>(`/api/weekends/${params.weekendId}/cups`)
    ]);
};


export const athletesAppLoader = async (): Promise<AthletesAppLoadedData> => {
    const money = await requestData<User>('/api/me/money');
    const weekend = new VismaWeekend(await requestData<VismaWeekendResponse>('/api/weekends/upcoming'));
    const athletes = await requestData<Athlete[]>('/api/athletes?filter_active=True&country=!RUS&country=!BLR');
    let nextSelection;

    const sortedSelections = weekend.selections.sort(
        (a, b) => {
            const trading_close_a = new Date(a.trading_close);
            const trading_close_b = new Date(b.trading_close);

            if (trading_close_a > trading_close_b) {
                return 1;
            } else if (trading_close_a < trading_close_b) {
                return -1;
            } else {
                return 0;
            }
        }
    );

    const remainingSelections = sortedSelections
        .filter((selection) => (new Date(selection.trading_close) > new Date()));
    if (remainingSelections.length) {
        nextSelection = remainingSelections[0];
    } else {
        nextSelection = sortedSelections[weekend.selections.length - 1]
    }

    return {
        athletes,
        loadedActiveSelection: nextSelection,
        loadedAfterDeadline: !weekend.trading_open,
        budget: money.budget,
        upcomingWeekend: weekend

    }
}