import { useContext, useEffect, useState } from "react";
import { Context, SettingsContext, UserContext } from "./Context";
import { RankedUser, Settings } from "./models";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactGA from 'react-ga';
import { googleLogout } from '@react-oauth/google';

export const useRedirectToLoginIfNeeded = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {loggedInState} = useContext<Context>(UserContext);
    useEffect(() => {
        if(!loggedInState.loggedIn) {
            navigate({
                pathname: '/login',
                search: createSearchParams([['next', location.pathname]]).toString()
            });
        }
    }, [loggedInState.loggedIn, location.pathname, navigate])
}
export const useRedirectToHomePageIfLoggedIn = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {loggedInState} = useContext<Context>(UserContext);
    useEffect(() => {
        if(loggedInState.loggedIn) {
            navigate({
                pathname: '/'
            });
        }
    }, [loggedInState.loggedIn, location.pathname, navigate])
}
export const useDoLogin = () => {
    const navigate = useNavigate();
    const userContext = useContext<Context>(UserContext);
    const [searchParams] = useSearchParams();
    return (user: RankedUser) => {
        userContext.loginUser(user);
        navigate(searchParams.get('next') || '/');
    }
}

export const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState<boolean>(false);
    const settings = useContext<Settings | undefined>(SettingsContext);

    useEffect(() => {
        if(settings && settings.analytics_id) {
            ReactGA.initialize(settings.analytics_id);
            setInitialized(true);
        }
    }, [settings]);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [initialized, location]);
};

export const useSignOut = (clientId: string | null) => () => {
    if(clientId) {
        googleLogout();
    }
    window.location.href = '/api/logout'
}

