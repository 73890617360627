import React from 'react';
import {
    ApplicationLink,
    MyUserPage,
    OwnedAthlete,
    SelectionAthletes,
    SelectionAthletesComponent,
    TeamSection,
    User,
    UserPage
} from '@fantasy/components';
import { OwnedVismaAthlete } from './models'
import { SelectionAthletesProps } from "@fantasy/components/src/components/UserApp";
import { WeekendResponse } from "@fantasy/components/src/components/responses.ts";
import { AthleteIndicators } from "./ReactComponents.tsx";

const VismaUserPage = () => {
    function createTeamSelection(user: User, upcomingWeekend: WeekendResponse): JSX.Element {
        return <VismaTeamSection user={user} upcomingWeekend={upcomingWeekend} key="selections"/>
    }

    return <UserPage teamSelectionFactory={createTeamSelection} />
}

const VismaMyUserPage = () => {
    function createTeamSelection(user: User, upcomingWeekend: WeekendResponse): JSX.Element {
        return <VismaTeamSection user={user} upcomingWeekend={upcomingWeekend} key="selections"/>
    }

    return <MyUserPage teamSelectionFactory={createTeamSelection} />
}

class VismaTeamSection extends TeamSection {

    createSelectionAthletesComponent(selection: SelectionAthletes) {
        return <VismaSelectionAthletesComponent {...selection} />;
    }
}

const VismaSelectionAthletesComponent = (props: SelectionAthletesProps) => {
    function createAthleteTable(athletes: OwnedAthlete[]): React.JSX.Element {
        return <VismaAthleteTable athletes={athletes as OwnedVismaAthlete[]} />;
    }

    return <SelectionAthletesComponent athleteTableFunction={createAthleteTable} {...props} />;
}

const VismaAthleteTable = (props: AthleteTableProps) => {

    function createRow(athlete: OwnedVismaAthlete): React.JSX.Element {
        return <tr key={athlete.athlete.athlete_id}>
            <td>
                <ApplicationLink href={ `/athletes/${athlete.athlete.athlete_id}` }>
                    {athlete.athlete.name}
                </ApplicationLink>
                <AthleteIndicators athlete={athlete.athlete} />
            </td>
            <td>
                {athlete.athlete.pro_team.name} ({athlete.athlete.pro_team.abbreviation})
            </td>
        </tr>;
    }

    function createHeader() {
        return <thead>
        <tr>
            <td>Name</td>
            <td>Pro Team</td>
        </tr>
        </thead>;
    }

    const rows = props.athletes.map((athlete) => createRow(athlete));
    return <table className="table">
        {createHeader()}
        <tbody>
            {rows}
        </tbody>
    </table>
}

interface AthleteTableProps {
    athletes: OwnedVismaAthlete[];
}

export { VismaUserPage, VismaMyUserPage }
